import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import Vuelidate from 'vuelidate'
import VueApexCharts from 'vue-apexcharts'
import JsonExcel from "vue-json-excel";



Vue.config.productionTip = false

Vue.use(VueApexCharts)
Vue.use(vuetify);
Vue.component("downloadExcel", JsonExcel);

// Vue.component('apexchart', VueApexCharts)
new Vue({
  vuetify,
  Vuelidate,
  router,
  VueApexCharts,
  render: h => h(App)
}).$mount('#app')
