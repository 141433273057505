import Vue from 'vue'
import Router from 'vue-router'

import Detail from '../views/Detail.vue'
import Home from '../views/Home.vue'
import HomeView from '../views/HomeView.vue'
import Input from '../views/Input.vue'
import ListPiutang from '../views/ListPiutang.vue'
import ListTransaction from '../views/ListTransaction.vue'
import Login from '../views/Login.vue'
import Onboard from '../views/Onboard.vue'
import Profile from '../views/Profile.vue'

Vue.use(Router)

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path : '/',
            name:'Onboard',
            component: Onboard,
            props:true
        },
        {
            path : '/home',
            name:'Home',
            component: Home
        },
        {
            path : '/input',
            name:'Input',
            component: Input
        },
        {
            path : '/listpiutang',
            name:'ListPiutang',
            component: ListPiutang
        },
        {
            path : '/listtransaction',
            name:'ListTransaction',
            component: ListTransaction
        },
        {
            path : '/login',
            name:'Login',
            component: Login
        },
        {
            path : '/detail',
            name:'Detail',
            component: Detail
        },
        {
            path : '/profile',
            name:'Profile',
            component: Profile
        },
        {
            path : '/homeview',
            name:'HomeView',
            component: HomeView
        }
    
    ]})