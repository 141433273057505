<template>
    <div class="bodyhome">
      
    <div  class="d-flex flex-row  mb-12">
      <v-card
      class=""
      min-height="760"
      width="256"
    >
      <v-navigation-drawer
        dark
        permanent
      >
        <v-list>
  
          <div class="pa-2">
            
            <v-btn @click="menu" block>
              Menu
            </v-btn>
          </div>
  
          <v-list-item
            v-for="item in items"
            :key="item.title"
            link
            v-on:click="navigate(item.route)"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
  
            <v-list-item-content >
              <v-list-item-title>{{ item.title }}</v-list-item-title> 
          </v-list-item-content>
          </v-list-item>
        </v-list>
  
        <template v-slot:append>
          <div class="pa-2">
            <v-btn @click="logout" block>
              Logout
            </v-btn>
          </div>
        </template>
      </v-navigation-drawer>
    </v-card>

    <v-container class="mx-auto" style="margin: 50px; max-width: 600px; padding: 0px;">
            
            <v-card class="bgblue" style="padding: 30px;">
       
             <v-container>
      
                <div class="d-flex">
               
                  <div>

                    <img width="200" height="200" src="../assets/profile.png"/>

                    <v-row style="margin-left: 50px;margin-top: 40px;" >


      <v-dialog
        v-model="dialog"
        persistent
        max-width="600px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-col cols="3" class="my-auto">
          <v-btn
            color="primary"
            dark
            v-bind="attrs"
            v-on="on"
          >
            Edit
          </v-btn>
          </v-col>
        </template>
        <v-card>
          <v-card-title>
            <span class="text-h5">User Profile</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
             
                <v-col cols="12">
                  <v-text-field
                    v-model="username"
                    label="Username"
                    required
                  ></v-text-field>
                </v-col>
           
      
                <v-col cols="12">
                  <v-text-field
                    v-model="password"       
                    label="Password*"
                    type="password"
                    required
                  ></v-text-field>
                </v-col>
                    
              </v-row>
            </v-container>
           
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="dialog = false"
            >
              Close
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="updateProfile"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      </v-row>
                  </div>

                 <div style="padding: 20px; text-align: left;margin-left: 20px;">

                  <h2>Username</h2>
                  <p> {{ username }}</p>
      
                  <h2>Role</h2>
                  <p>{{ role }}</p>
      
                  <h2>Password</h2>
                  <p>*******</p>
      
                 </div>
                </div>
             </v-container>
          </v-card>
          
        </v-container>
  
    </div>
  
    </div>
  </template>
  
  <script>
  import Swal from 'sweetalert2'
  import axios from 'axios';
  import constant from '../util/constant';

  export default {
    name: 'profile-page',
    data () {
        return {
          isSupervisor:false,
          isAdmin:false,
          name:"",
          username:"",
          email:"", 
          role:"",
          password:"",
          saldo:"0",
          items: [
            { title: 'Transaction', icon: 'mdi-view-dashboard', route: '/listtransaction' },
            { title: 'Tagihan', icon: 'mdi-dialpad', route: '/listpiutang' },
            { title: 'Input', icon: 'mdi-arrow-up-bold-box-outline', route: '/input' },
            { title: 'Detail', icon: 'mdi-domain', route: '/detail' },
            { title: 'Profile', icon: 'mdi-account-box', route: '/profile' }
          ],
          itemAdmin:[
            { title: 'Transaction', icon: 'mdi-view-dashboard', route: '/listtransaction' },
            { title: 'Tagihan', icon: 'mdi-dialpad', route: '/listpiutang' },
            { title: 'Input', icon: 'mdi-arrow-up-bold-box-outline', route: '/input' },
            { title: 'Detail', icon: 'mdi-domain', route: '/detail' },
            { title: 'Profile', icon: 'mdi-account-box', route: '/profile' }
        ],
       itemSpv: [
            { title: 'Transaction', icon: 'mdi-view-dashboard', route: '/listtransaction' },
            { title: 'Detail', icon: 'mdi-domain', route: '/detail' },
            { title: 'Profile', icon: 'mdi-account-box', route: '/profile' }
        ],
          dialog: null
        }
      },
      methods:{
        menu(){
        this.$router.push('/home');
      },
        navigate(link) {
        this.$router.push(link);
        },
        updateProfile(){
    
          let a = this
          let userid = localStorage.getItem("userid")

          axios.put(constant.BASE_URL + "account",{
              id:userid,
              username: a.username,
              password: a.password,
              role: a.role,
              saldo:a.saldo 
          })
          .then(function (response) {

          console.log(response)

          a.dialog = false

          Swal.fire({
            icon: 'success',
            title: 'Data berhasil diubah',
            showConfirmButton: false
          })

          }).catch(function (error) {   

            a.dialog = false

            Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text:  error
          })

          console.log(error);

          })

        },
        getData(){

           let a = this

           let userid = localStorage.getItem("userid")

          axios.get(constant.BASE_URL + "account/" + userid)
          .then(function (response) {

          var newitem = response.data.data[0]

          a.username = newitem.username
          a.password = newitem.password
          a.role = newitem.role
          a.saldo = newitem.saldo
          

          }).catch(function (error) {     
          console.log(error);
          })

        },
        logout(){
      Swal.fire({
          title: "<h3 style='font-family:cambria;'>Lanjutkan Logout</h3>",
          showCancelButton: true,
          icon:'success',
          confirmButtonColor: '#3085d6',
          confirmButtonText: "<h3 style='font-family:cambria;'>Logout</h3>",
          cancelButtonText: "<h3 style='font-family:cambria;'>Cancel</h3>"

        }).then((result) => {
          if (result.isConfirmed) {
            localStorage.clear()
              this.$router.push("/");
          }
        })

    }
      },

    created(){
    var userid = localStorage.getItem("userid")
    if (userid == null) {
      this.$router.push("/");
    }

    var role = localStorage.getItem("userRole")

    if(role == "admin"){
      this.isAdmin = true
      this.items = this.itemAdmin
    }

    if(role == "supervisor"){
      this.isSpv = true
      this.items = this.itemSpv
    }

    this.getData()
  }
  
  }
  
  </script>
  <style>
  
  .bodyhome{
    margin-top: -60px;
  }
  
  
  </style>