<template>
<div class="body fill-height">

<div class="d-flex justify-center">
<h1>System Ticketing</h1>
</div>

  <v-container
    class="d-flex justify-center mb-6 "
    flat
    tile
  >
  <v-row class="mx-auto justify-center">
  <v-card
@click="login"
 
  class="my-12 card"
  max-width="374"
>

  <v-img
    height="250"
    width="250"
    src="../assets/supervisor.png"
  ></v-img>

  <v-card-title class="d-flex justify-center mt-10"><p>SUPERVISOR</p></v-card-title>

</v-card>


  <v-card
  @click="login"
  class="my-12 card"
  max-width="374"
>

  <v-img
    height="250"
    width="250"
    src="../assets/admin.png"
  ></v-img>

  <v-card-title class="d-flex justify-center mt-10"><p>ADMIN</p></v-card-title>

</v-card>

</v-row>

  </v-container>

</div>
</template>
 
<script>
export default {
  name: 'onboard-page',

  methods: {
    login(){
      this.$router.push('/login');
    }
  },
  created(){

    var userid = localStorage.getItem("userid")
    if (userid != null) {
      this.$router.push("/home");
    }

  }


}
</script>
<style>
.body {
    background-image: url(../assets/bgmain.jpg);
    background-repeat: repeat-y ;
    background-size:  cover;
    margin-top: -60px;
  
}

.card {
  margin: 20px;
  padding: 16px;
  /* background: linear-gradient(180deg, #366660 0%, rgba(35, 48, 46, 0) 100%); */
  background: rgba(86, 207, 255, 0.7);
}

p{
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  text-decoration-line: underline;
  color: #262fa1;
}

h1{
  font-style: normal;
  font-weight: 500;
  font-size: 64px;
  /* color: #34758E; */
}




</style>