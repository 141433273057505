<template>
<div class="bodyhome">

  <div  class="d-flex flex-row  mb-12">
    <v-card
    class=""
    min-height="760"
    width="256"
  >
    <v-navigation-drawer
      dark
      permanent
    >
      <v-list>

        <div class="pa-2">
          
          <v-btn v-on:click="menu" block>
            Menu
          </v-btn>
        </div>

        <v-list-item
          v-for="item in items"
          :key="item.title"
          link
          v-on:click="navigate(item.route)"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content >
              <v-list-item-title>{{ item.title }}</v-list-item-title> 
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <template v-slot:append>
        <div class="pa-2">
          <v-btn @click="logout" block>
            Logout
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>

  </v-card>

  <v-container>

<v-card 

class="background bgblue mx-auto" style="padding: 50px; margin: 50px; max-width: 850px;">

<form class="d-flex " >

<div >
  <v-menu
        ref="menu1"
        v-model="menu1"
        :close-on-content-click="false"
        :return-value.sync="date"
        transition="scale-transition"
        offset-y
        max-width="290px"

      >
        <template v-slot:activator="{  on, attrs }">
          <v-text-field
            v-model="tgltransaksi"
            label="Tgl Transaksi"
            readonly
            v-on="on"
            v-bind="attrs"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="tgltransaksi"
          no-title
          scrollable
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="menu1 = false"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.menu1.save(date)"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>

<v-text-field
v-model="namapemesan"
label="Nama Pemesan"
></v-text-field>

<v-text-field
v-model="nohp"
label="No HP"   
type="number"
></v-text-field>

<v-text-field
v-model="namapengguna"
label="Nama Pengguna"
></v-text-field>

<v-text-field
v-model="noktp"
label="No Ktp"
type="number"
></v-text-field>

</div>

<div style="margin-left:50px;">

<v-text-field
v-model="email"
label="Email"
type="email"
></v-text-field>

<v-text-field
v-model="modatransportasi"
label="Jenis Tiket (Maskapai)"
></v-text-field>

<v-menu
        ref="menu2"
        v-model="menu2"
        :close-on-content-click="false"
        :return-value.sync="date"
        transition="scale-transition"
        offset-y
        max-width="290px"

      >
        <template v-slot:activator="{  on, attrs }">
          <v-text-field
            v-model="tglberangkat"
            label="Tgl Keberangkatan"
            readonly
            v-on="on"
            v-bind="attrs"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="tglberangkat"
          no-title
          scrollable
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="menu2 = false"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.menu2.save(date)"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>

<v-text-field
v-model="tempatberangkat"
label="Keberangkatan"
></v-text-field>

<v-text-field
v-model="tujuan"
label="Tujuan"
></v-text-field>

</div>

<div style="margin-left:50px;">

<v-text-field
v-model="kodebooking"
label="Kode Booking"
></v-text-field>

<v-text-field
v-model="totaltagihan"
label="Harga Tiket"
type="number"
></v-text-field>

<v-text-field
v-model="kategori"
label="Kategori"
></v-text-field>

<v-text-field
v-model="komisicitos"
label="Komisi Citos"
type="number"
></v-text-field>


<v-text-field
v-model="komisijamkrindo"
label="Komisi Jamkrindo"
type="number"
:disabled=true
></v-text-field>
</div>

</form>

<v-row class="mt-5">

  <v-col class="my-auto text-left">
<v-btn @click="clear" color="error">
clear
</v-btn>
</v-col>

<v-col class="my-auto">
<v-btn @click="inputData" color="success">
submit
</v-btn>
<v-dialog
v-model="dialog"
hide-overlay
persistent
width="300"
>
<v-card
  color="primary"
  dark
>
  <v-card-text>
    Please stand by
    <v-progress-linear
      indeterminate
      color="white"
      class="mb-0"
    ></v-progress-linear>
  </v-card-text>
</v-card>
</v-dialog>
</v-col>


</v-row>

</v-card>
</v-container>

  </div>

</div>
</template>

<script>
import axios from 'axios';
import constant from '../util/constant';
import Swal from 'sweetalert2'


export default {
  name: 'input-page',
  data () {
      return {
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menu1: false,
      modal: false,
      menu2: false,
      isSupervisor:false,
      isAdmin:false,
      namapengguna:"",
      namapemesan:"",
      nohp:"",
      noktp:"",
      modatransportasi:"",
      jadwal:"",
      noresi:"",
      email:"pt.bintangjamtamasejahtera@gmail.com",
      refund:"",
      status:"",
      komisi:"",
      profitshare:"",
      nominal:"",
      tgltransaksi:"",
      tglberangkat:"",
      maskapai:"",
      kategori:"",
      tempatberangkat:"",
      tujuan:"",
      kodebooking:"",
      totaltagihan:"0",
      dialog: false,
      komisicitos:"",
      komisijamkrindo:"",
      itemstatus:["piutang", "lunas"],
      items: [
        { title: 'Transaction', icon: 'mdi-view-dashboard', route: '/listtransaction' },
        { title: 'Tagihan', icon: 'mdi-dialpad', route: '/listpiutang' },
        { title: 'Input', icon: 'mdi-arrow-up-bold-box-outline', route: '/input' },
        { title: 'Detail', icon: 'mdi-domain', route: '/detail' },
        { title: 'Profile', icon: 'mdi-account-box', route: '/profile' }
      ],
      }
    },
  
    methods:{
      menu(){
        this.$router.push('/home');
      },
      navigate(link) {
      this.$router.push(link);
      },
      clear(){
        this.namapengguna=""
        this.namapemesan=""
        this.nohp=""
        this.noktp=""
        this.modatransportasi=""
        this.jadwal=""
        this.noresi=""
        this.email="pt.bintangjamtamasejahtera@gmail.com"
        this.refund=""
        this.status=""
        this.komisi=""
        this.profitshare=""
        this.nominal=""
        this.tgltransaksi=""
        this.maskapai=""
        this.kategori=""
        this.tempatberangkat=""
        this.tujuan=""
        this.kodebooking=""
        this.totaltagihan=""
        this.tglberangkat=""
        this.komisicitos =""
        this.komisijamkrindo = ""

      },
      inputData(){

       let a = this
       this.dialog = true

        axios.post(constant.BASE_URL + "ticket", {
            "namapengguna":a.namapengguna,
            "namapemesan":a.namapemesan,
            "nohp":a.nohp,
            "noktp":a.noktp,
            "modatransportasi":a.modatransportasi,
            "jadwal":a.tglberangkat,
            "noresi":a.noresi,
            "email":a.email,
            "refund":0,
            "status":"belum",
            "komisi":1,
            "tglberangkat":a.tglberangkat,
            "profitshare":0,
            "nominal":a.nominal,
            "tgltransaksi":a.tgltransaksi,
            "maskapai":a.maskapai,
            "kategori":a.kategori,
            "tempatberangkat":a.tempatberangkat,
            "tujuan":a.tujuan,
            "kodebooking":a.kodebooking,
            "totaltagihan":a.totaltagihan,
            "komisicitos": a.komisicitos,
            "tglrefund": "",
            "addon": "0",
            "tgladdon": "",
            "refundout":0,
            "tglrefundout":"",
            "statusrefund":"-"

        }).then(function (response) {

          console.log(response);

          a.dialog = false

          Swal.fire({
            icon: 'success',
            title: 'Data berhasil dibuat',
            showConfirmButton: false,
            timer: 1500
          })

          a.clear();
          

        }).catch(function (error) {
          a.dialog = false
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text:  error
          })
          console.log(error);
        })

      },
      logout(){
      Swal.fire({
          title: "<h3 style='font-family:cambria;'>Lanjutkan Logout</h3>",
          showCancelButton: true,
          icon:'success',
          confirmButtonColor: '#3085d6',
          confirmButtonText: "<h3 style='font-family:cambria;'>Logout</h3>",
          cancelButtonText: "<h3 style='font-family:cambria;'>Cancel</h3>"

        }).then((result) => {
          if (result.isConfirmed) {
            localStorage.clear()
              this.$router.push("/");
          }
        })

    }
    },
    watch: {
      totaltagihan(newQuestion) {
         this.komisijamkrindo = newQuestion * 1 / 100
    }
  

    },
    created(){
    var userid = localStorage.getItem("userid")
    if (userid == null) {
      this.$router.push("/");
    }

    var role = localStorage.getItem("userRole")

  if(role == "admin"){
    this.isAdmin = true
  }

  if(role == "supervisor"){
    this.$router.push("/home");
  }

  }

}

</script>
<style>
.bodyhome{
  margin-top: -60px;
}

.bgblue{
  background: rgba(86, 207, 255, 0.62);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
}


</style>